var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// LIB
import React, { lazy, Suspense } from "react";
// Components
import Summary from "./Summary";
import { venueFallback } from "../fallbacks/venueFallback";
var VenueOverview = lazy(function () { return import('../VenueOverview'); });
var Rating = lazy(function () { return import('../../../search/components/Rating'); });
import { RatingIcons } from "../../../search/interfaces/SearchPage";
var ReviewVenueSummary = /** @class */ (function (_super) {
    __extends(ReviewVenueSummary, _super);
    function ReviewVenueSummary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.render = function () {
            var summaryHeader = _this.getHeader();
            var dateOfVisit = _this.getDateFormatted();
            var venueSkeleton = venueFallback();
            return (React.createElement("section", { className: "summary" },
                summaryHeader,
                React.createElement(Suspense, { fallback: venueSkeleton }, _this.props.values.place !== null && (React.createElement(React.Fragment, null,
                    React.createElement("h3", { className: "h2" }, "Venue Information"),
                    React.createElement(VenueOverview, { venue: _this.props.values.venue, hideShadow: true })))),
                React.createElement("dl", { className: "summary__list data-list" },
                    _this.props.values.review.dateOfVisit && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Date of Visit"),
                        React.createElement("dd", null, dateOfVisit))),
                    _this.props.values.review.rating && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Your Rating"),
                        React.createElement("dd", null,
                            React.createElement(Suspense, { fallback: React.createElement("div", { className: "rating" }) },
                                React.createElement(Rating, { number: _this.props.values.review.rating, icon: RatingIcons.Heart, fillRest: true }),
                                React.createElement("span", null, _this.getRatingString(_this.props.values.review.rating)))))),
                    _this.props.values.review.title && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Title of review"),
                        React.createElement("dd", null, _this.props.values.review.title))),
                    _this.props.values.review.content && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Your Review"),
                        React.createElement("dd", null, _this.props.values.review.content))),
                    _this.props.values.review.menuTopTips && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Menu top tips"),
                        React.createElement("dd", null, _this.props.values.review.menuTopTips))),
                    _this.props.values.review.venueTopTips && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Venue top tips"),
                        React.createElement("dd", null, _this.props.values.review.venueTopTips))),
                    _this.props.values.review.recommendation && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "What dish do you recommend?"),
                        React.createElement("dd", null, _this.props.values.review.recommendation))),
                    (_this.props.values.review.allergy.length > 0) && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Allergens Considered"),
                        React.createElement("dd", null, _this.concatStrings(_this.props.values.review.allergy)))),
                    _this.props.values.user.emailAddress && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Email Address"),
                        React.createElement("dd", null, _this.props.values.user.emailAddress))),
                    _this.props.values.user.name && (React.createElement("div", { className: "data-list__item" },
                        React.createElement("dt", null, "Display Name"),
                        React.createElement("dd", null, _this.props.values.user.name))))));
        };
        return _this;
    }
    return ReviewVenueSummary;
}(Summary));
export default ReviewVenueSummary;
